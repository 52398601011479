"use client";

import { sendCode, verifyCode } from "./actions";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import Captcha from "@/components/Captcha";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { codeRequestSchema, codeVerifySchema } from "./shared";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Loader2 } from "lucide-react";

function CodeForm({ email }: { email: string }) {
  const [loading, setLoading] = useState(false);
  const codeForm = useForm<z.infer<typeof codeVerifySchema>>({
    resolver: zodResolver(codeRequestSchema),
    defaultValues: { code: "", email },
  });
  return (
    <Form {...codeForm}>
      <form
        action={async (data) => {
          if (loading) return;
          setLoading(true);
          console.log(data);
          const res = await verifyCode(data);
          if (res.errors) {
            codeForm.setError("root", {
              message: res.errors.formErrors.join(", "),
            });
            for (const k in res.errors.fieldErrors) {
              codeForm.setError(k as keyof typeof res.errors.fieldErrors, {
                message:
                  res.errors.fieldErrors[
                    k as keyof typeof res.errors.fieldErrors
                  ]!.join(", "),
              });
            }
          }
          setLoading(false);
        }}>
        <div className="h-96 max-w-[300px] mx-auto">
          <div className="my-8">
            <h2 className="text-xl font-semibold">Enter the Code</h2>
            <p className="text-slate-600 mt-2 font-light">
              Enter the code we sent to your email
            </p>
            {codeForm.formState.errors.root && (
              <p>{codeForm.formState.errors.root?.message}</p>
            )}
          </div>
          <FormField
            control={codeForm.control}
            name="email"
            render={({ field }) => <input type="hidden" {...field} />}
          />
          <div className="grid gap-1 my-2">
            <FormField
              control={codeForm.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="sr-only" htmlFor={field.name}>
                    Code
                  </FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="000000" type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Button className="bg-slate-950 w-full">
            {loading && <Loader2 className=" mr-2 h-4 w-4 animate-spin" />}
            Sign In
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default function EmailForm({}: {}) {
  const [sentEmailTo, setSentEmailTo] = useState("");
  const [loading, setLoading] = useState(false);
  const emailForm = useForm<z.infer<typeof codeRequestSchema>>({
    resolver: zodResolver(codeRequestSchema),
    defaultValues: { email: "" },
  });
  if (sentEmailTo) return <CodeForm email={sentEmailTo} />;
  return (
    <Form {...emailForm}>
      <form
        action={async (data) => {
          if (loading) return;
          setLoading(true);
          console.log(data);
          const res = await sendCode(data);
          if (res.error) {
            emailForm.setError("root", { message: res.error });
          } else {
            setSentEmailTo(data.get("email") as string);
          }
          setLoading(false);
        }}>
        <div className="h-96 max-w-[300px] mx-auto">
          <div className="my-8">
            <h2 className="text-xl font-semibold">
              Login to Anystreet Partners
            </h2>
            <p className="text-slate-600 mt-2 font-light">
              Use your work email
            </p>
            {emailForm.formState.errors.root && (
              <p>{emailForm.formState.errors.root?.message}</p>
            )}
          </div>
          <div className="grid gap-1 my-2">
            <FormField
              control={emailForm.control}
              name="email"
              render={({ field }) => (
                <FormField
                  control={emailForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="sr-only" htmlFor={field.name}>
                        Email
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="name@example.com"
                          type="email"
                          autoCapitalize="none"
                          autoComplete="email"
                          autoCorrect="off"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            />
          </div>
          <Button className="bg-slate-950 w-full">
            {loading && <Loader2 className=" mr-2 h-4 w-4 animate-spin" />}
            Sign In with Email
          </Button>
          <div className="my-5">
            <Captcha
              onSuccess={(token) =>
                emailForm.setValue("cf-turnstile-response", token)
              }
            />
          </div>
        </div>
      </form>
    </Form>
  );
}
