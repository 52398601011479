import z from "zod";

export const codeRequestSchema = z.object({
  email: z.string().email(),
  "cf-turnstile-response": z.string({
    required_error: "Must complete CAPTCHA",
  }),
});

export const codeVerifySchema = z.object({
  email: z.string().email(),
  code: z.string(),
});
