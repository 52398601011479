"use client";

import Script from "next/script";
import { useEffect, useRef, useState } from "react";

export default function Captcha({
  onSuccess = () => {},
}: {
  onSuccess?: (token: string) => void;
}) {
  const [ready, setReady] = useState(false);
  const onSuccessRef = useRef(onSuccess);
  const ref = useRef<HTMLDivElement>(null);
  onSuccessRef.current = onSuccess;
  useEffect(() => {
    if (!ready) return;
    (window as any).turnstile.render(ref.current, {
      sitekey: process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY,
      appearance: "interaction-only",
      callback: function (token: string) {
        onSuccessRef.current(token);
      },
    });
  }, [ready]);

  return (
    <>
      <div ref={ref}></div>
      <Script
        src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"
        strategy="lazyOnload"
        onReady={() => setReady(true)}
      />
    </>
  );
}
